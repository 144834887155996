import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    },
  },
});
root.render(
  <>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <div className="container-fluid">
          <div className='row justify-content-md-center header'>
            <div className="col-md-2" style={{ textAlign: 'center' }}>
              <img src="./img/BE.png" width="125px" height="100px" alt='Bahador Emrani' />
            </div>
          </div>
          <div className="row justify-content-md-center header">
            <div className="col-md-5" style={{ paddingTop: '10px', textAlign: 'center' }} >
              <h4 className='welcome-text-header'>Welcome to my view of the world</h4>
              <p className='welcome-text'>I would like to capture my perspective of the scenes ;-)</p>
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='col-12'>
              <App />
            </div>
          </div>
          <br></br>

          <div className='row justify-content-md-center'>
            <div className='col-12'>
              <footer>
                <div id="footer">
                  <p>
                    Copyright <a href="http://emrani.net">EmRaNi.Net</a>. All Right Reserved.
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </React.StrictMode></>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
