import axios from "axios";
import { Photo } from "react-photo-album";
import { useQuery } from "react-query";

const apikey = "378de1b4110ea6677fd201cb8a492375";
const photoSetId = "72177720304692876";


export interface IPhoto {
    id: string,
    secret: string,
    server: string,
    farm: string,
    title: string,
    originalsecret: string,
    originalformat: string,
}
export interface IphotoSet {
    page: string,
    per_page: string,
    pages: string,
    title: string,
    photo: IPhoto[]
}
export interface Iflicker {
    stat: string,
    photoset: IphotoSet
}
export const useGetPhotos = () => {
    return useQuery<Photo[], Error>("photos-list", async () => {
        const url = `https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=${apikey}&format=json&photoset_id=${photoSetId}&extras=original_format,originalsecret`;
        const response = await axios.get<string>(url);
        const convertValue: string = response.data.replaceAll('jsonFlickrApi(', '').replaceAll(')', '');
        const jsonValue: Iflicker = JSON.parse(convertValue);
        return jsonValue.photoset?.photo.map((result: IPhoto, index: number) => {
            const baseUrl = 'https://farm' + result.farm + '.static.flickr.com/' +
                result.server + '/' + result.id + '_' + result.secret;
            return { key: index.toString(), alt: result.title, title: result.title, src: `${baseUrl}.${result.originalformat ?? 'jpg'}`, width: 1000, height: 1000, images: [{ src: `${baseUrl}_b.${result.originalformat ?? 'jpg'}`, width: 2048, height: 2048 }] };
        });
    })
};

