import React, { useCallback } from 'react';
import './App.css';
import PhotoAlbum, { RenderPhotoProps } from 'react-photo-album';
import { useGetPhotos } from './services/photos-service'

const padding = 30;
const spacing = 50;
const layout = "masonry"


function App() {
  const { data: photoData, error: photoError, isLoading: isPhotoLoading } = useGetPhotos();
  const renderPhoto = useCallback(
    ({ imageProps: { alt, style, src, ...rest }, photo }: RenderPhotoProps) => (
      <a href={photo?.images?.[0].src ?? src} title={alt} data-gallery='' data-index={photo.key}>
        <img
          alt={alt}
          style={{
            ...style,
            borderRadius: padding > 2 ? "4px" : 0,
            boxShadow:
              spacing > 0
                ? "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
                : "none",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            aspectRatio: 0,
            width: "100%",
            backgroundColor: 'white'
          }}
          src={src}
          {...rest}
        />
      </a>
    ),
    []
  );

  if (isPhotoLoading) {
    return <div className="sk-cube-grid">
      <div className="sk-cube sk-cube1"></div>
      <div className="sk-cube sk-cube2"></div>
      <div className="sk-cube sk-cube3"></div>
      <div className="sk-cube sk-cube4"></div>
      <div className="sk-cube sk-cube5"></div>
      <div className="sk-cube sk-cube6"></div>
      <div className="sk-cube sk-cube7"></div>
      <div className="sk-cube sk-cube8"></div>
      <div className="sk-cube sk-cube9"></div>
      <div className="text-center" id="loadingPercent">
      </div>
    </div>
  }

  if (photoError) {
    console.error('error', photoError);
    return <div className='text-center alert alert-danger' > Connection to the server is terminated or censored by your ISP.</div >
  }

  return (
    <>
      <>
        <PhotoAlbum
          photos={photoData ?? []}
          layout={layout}
          padding={padding}
          spacing={30}
          renderPhoto={renderPhoto} />

        <div id="blueimp-gallery" className="blueimp-gallery blueimp-gallery-controls">
          <div className="slides"></div>
          <h3 className="title"> </h3>
          <a href='' className="prev">‹</a>
          <a href='' className="next">›</a>
          <a href='' className="close">×</a>
          <ol className="indicator"></ol>
          <div className="modal fade">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" aria-hidden="true">&times;</button>
                  <h4 className="modal-title"> </h4>
                </div>
                <div className="modal-body next"><img src='img/loading.gif' alt='loading' /></div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default pull-left prev">
                    <i className="glyphicon glyphicon-chevron-left"></i>
                    Previous
                  </button>
                  <button type="button" className="btn btn-primary next">
                    Next
                    <i className="glyphicon glyphicon-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default App;
